import { useState } from "react";

import { Box, Grid2, InputAdornment, OutlinedInput, Stack, Typography } from "@mui/material"

import GPTIcon from "./assets/gpt.png"
import DexImg from "./assets/dex.png"
import XImg from "./assets/twitter.png"
// import LogoImg from "./assets/logo.png"
import BackgroundImg from "./assets/background.jpg"

import SendIcon from '@mui/icons-material/Send';
import StopIcon from '@mui/icons-material/Stop';

import useMessagesScroll from "./hooks";
import { Message, PromptMessage } from "./types";

const App = () => {

  const [message, setMessage] = useState<string>('')
  const [prompt, setPrompt] = useState<PromptMessage[]>([])
  const [messages, setMessages] = useState<Message[]>([])
  const [loading, setLoading] = useState<boolean>(false)

  const { messagesEndRef } = useMessagesScroll(messages);

  const sendMsg = async () => {
    if (message && !loading) {
      try {
        setLoading(true)
        const userMsg: Message = { type: "user", message: message }
        setMessages([...messages, userMsg])
        setPrompt([...prompt, { type: "text", text: message }])
        setMessage('')

        const response = await fetch(process.env.REACT_APP_API_URL as string, {
          headers: {
            "Content-Type": "application/json",
          },
          method: "POST",
          body: JSON.stringify({ message: message, prompt: prompt }),

        });

        const result = await response.json()
        setMessages([...messages, userMsg, { type: "chatgpt", message: result.message }])
        setLoading(false)
      } catch (error) {
        console.error(error)
        setLoading(false)
      }
    }
  }

  const handleChange = (e: any) => {
    if (e.key === 'Enter' && !loading) {
      sendMsg()
    }
  }

  return (
    <Stack className="home">

      <Stack sx={{ position: "absolute", height: "100vh", width: "100%", justifyContent: 'center' }}>
        <Box component="img" src={BackgroundImg} sx={{ height: "100%" }}></Box>
      </Stack>

      {/* <Stack className="header" direction="row" spacing={1} alignItems="center">
        <Box component="img" sx={{ height: "50px" }} src={LogoImg}></Box>
      </Stack> */}

      <Stack px={2} sx={{ height: "100%", position: "relative", zIndex: 1 }}>
        <Grid2 container display="flex" justifyContent="center" sx={{ height: "100%" }}>
          <Grid2 size={{ md: 5, xs: 12, sm: 12 }} sx={{ height: "100%", display: "flex", alignItems: "center" }}>
            <Stack className="chat" padding={2} borderRadius={3}>
              <Stack direction="row" spacing={3}>
                <a href="https://x.com/gpt_doge" target="_blank" rel="noreferrer"  >
                  <Box component="img" src={XImg} sx={{ height: "30px", cursor: "pointer" }}>
                  </Box>
                </a>
                <a href="https://dexscreener.com/" target="_blank" rel="noreferrer" >
                  <Box component="img" src={DexImg} sx={{ height: "30px", cursor: "pointer" }}>
                  </Box>
                </a>

              </Stack>
              <Stack className="messages" ref={messagesEndRef} flexGrow={1} mt={3} spacing={1}>
                {
                  messages.map((item: Message, key: number) => {
                    return (
                      <Stack key={key} className={item.type === 'user' ? "user-msg" : 'chatgpt-msg'} direction="row">
                        {
                          item.type === "chatgpt" && (
                            <Box component="img" className="img" src={GPTIcon}></Box>
                          )
                        }
                        <Typography >{item.message}</Typography>
                      </Stack>

                    )
                  })
                }

                {
                  messages.length === 0 && (
                    <Stack alignItems="center" justifyContent="center" height={1}>
                      <Typography sx={{ fontWeight: "bold" }} variant="h5" >Welcome to DogeGPT!!!</Typography>
                    </Stack>
                  )
                }

              </Stack>

              <Stack className="controls" my={5}>
                <OutlinedInput
                  fullWidth
                  sx={{ borderRadius: 10 }}
                  placeholder="Message DogeGPT"
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  onKeyPress={handleChange}
                  endAdornment={
                    <InputAdornment position="end">
                      {
                        !loading ?
                          <SendIcon sx={{ cursor: "pointer" }} onClick={() => sendMsg()} />
                          :
                          <StopIcon />
                      }
                    </InputAdornment>
                  }
                />
              </Stack>

            </Stack>

          </Grid2>
        </Grid2>
      </Stack>
    </Stack >
  )
}

export default App