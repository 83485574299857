// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
___CSS_LOADER_EXPORT___.push([module.id, "@import url(https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap);"]);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `* {
  font-family: "Comic Neue", cursive !important;
}

.home {
  height: 100vh;
  overflow: hidden;
  background: #212121;
}

body {
  margin: 0 !important;
}

.header {
  padding: 16px 20px;
  font-weight: 600;
  position: sticky;
  display: flex;
}

.chat {
  height: 60vh;
  background: #d0a451;
  width: 100%;
  border: 4px solid #9f6224;
}

.messages {
  overflow: auto;
}

.user-msg {
  padding: 10px;
  background-color: #9f6224;
  border-radius: 10px;
  width: fit-content;
  color: white;
  align-self: flex-end;
}

* {
  -ms-overflow-style: none;
}

::-webkit-scrollbar {
  display: none;
}

.chatgpt-msg {
  padding: 10px;
  width: fit-content;
  color: white;
  align-self: flex-start;
  gap: 10px;
}
.chatgpt-msg .img {
  height: 30px;
  width: 30px;
  border-radius: 30px;
}

.Mui-focused fieldset {
  border-color: white !important;
}`, "",{"version":3,"sources":["webpack://./src/assets/index.scss"],"names":[],"mappings":"AAEA;EACI,6CAAA;AAAJ;;AAGA;EACI,aAAA;EACA,gBAAA;EACA,mBAAA;AAAJ;;AAGA;EACI,oBAAA;AAAJ;;AAGA;EACI,kBAAA;EACA,gBAAA;EAEA,gBAAA;EACA,aAAA;AADJ;;AAMA;EACI,YAAA;EACA,mBAAA;EACA,WAAA;EACA,yBAAA;AAHJ;;AAWA;EACI,cAAA;AARJ;;AAWA;EACI,aAAA;EACA,yBAAA;EACA,mBAAA;EACA,kBAAA;EACA,YAAA;EACA,oBAAA;AARJ;;AAWA;EACI,wBAAA;AARJ;;AAWA;EACI,aAAA;AARJ;;AAWA;EACI,aAAA;EAGA,kBAAA;EACA,YAAA;EACA,sBAAA;EACA,SAAA;AAVJ;AAYI;EACI,YAAA;EACA,WAAA;EACA,mBAAA;AAVR;;AAeI;EACI,8BAAA;AAZR","sourcesContent":["@import url('https://fonts.googleapis.com/css2?family=Comic+Neue:ital,wght@0,300;0,400;0,700;1,300;1,400;1,700&display=swap');\n\n* {\n    font-family: \"Comic Neue\", cursive !important;\n}\n\n.home {\n    height: 100vh;\n    overflow: hidden;\n    background: #212121;\n}\n\nbody {\n    margin: 0 !important;\n}\n\n.header {\n    padding: 16px 20px;\n    font-weight: 600;\n\n    position: sticky;\n    display: flex;\n\n    // color: #b4b4b4;\n}\n\n.chat {\n    height: 60vh;\n    background: #d0a451;\n    width: 100%;\n    border: 4px solid #9f6224;\n    // color: white;\n\n    // svg {\n    //     color: white;\n    // }\n}\n\n.messages {\n    overflow: auto;\n}\n\n.user-msg {\n    padding: 10px;\n    background-color: #9f6224;\n    border-radius: 10px;\n    width: fit-content;\n    color: white;\n    align-self: flex-end;\n}\n\n* {\n    -ms-overflow-style: none;\n}\n\n::-webkit-scrollbar {\n    display: none;\n}\n\n.chatgpt-msg {\n    padding: 10px;\n    // background-color: #2f2f2f;\n    // border-radius: 30px;\n    width: fit-content;\n    color: white;\n    align-self: flex-start;\n    gap: 10px;\n\n    .img {\n        height: 30px;\n        width: 30px;\n        border-radius: 30px;\n    }\n}\n\n.Mui-focused {\n    fieldset {\n        border-color: white !important;\n    }\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
